import React from 'react';
import LazyLoad from 'react-lazy-load';

const skillArr = [
  'JavaScript (ES6+)',
  'HTML & CSS',
  'React',
  'React Native',
  'TypeScript',
  'GraphQL',
  'Git',
  'Docker',
  'Kubernetes',
];

const Profile = () => (
  <section id="about">
    <div className="headTitle">
      <h1 style={{ margin: '2rem' }}>
        <span className="subNumber">01.</span>About
      </h1>
      <h1 className="headLine subLine">{}</h1>
    </div>
    <div className="profileDiv">
      <div className="textArea">
        <p>
          Hello! I&apos;m Jay C, a software engineer based in Los Angeles, CA.
        </p>
        <p>
          I like creating meaningful applications, whether that be websites,
          mobile apps, or anything in between. My goal is to build functional,
          interactive, and accessible products that provide pixel-perfect,
          performant experiences.
        </p>
        <p>Here are a few technologies I&apos;ve been working on recently:</p>
        <ul className="techList">
          {skillArr.map((skill, index) => {
            return (
              <li key={`${index}${skill}`}>
                <span>→</span>
                {skill}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="profileArea">
        <LazyLoad>
          <img
            src="https://res.cloudinary.com/dzntfr1yg/image/upload/v1670115615/AvatarMaker_hnydlc.jpg"
            alt="jay"
            className="profileImg"
          />
        </LazyLoad>
        <div className="profileImageContainer"></div>
      </div>
    </div>
  </section>
);

export default Profile;
