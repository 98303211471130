// extracted by mini-css-extract-plugin
export var ProjectCardAction = "itemStyle-module--ProjectCardAction--kQLeI";
export var ProjectCardContent = "itemStyle-module--ProjectCardContent--5aQmi";
export var description = "itemStyle-module--description--VToBB";
export var detailDiv = "itemStyle-module--detailDiv--QaEIQ";
export var detailLink = "itemStyle-module--detailLink--r14SE";
export var details = "itemStyle-module--details--NZWzA";
export var imageContainer = "itemStyle-module--imageContainer--eelZz";
export var root = "itemStyle-module--root--ZdTb-";
export var social = "itemStyle-module--social--q5MKD";
export var tech = "itemStyle-module--tech--ZdHju";